<template>
	<div class="main-content">
		<div class="meunTop">
			<div class="meunLine"></div>
			<div class="meunTitle">对账管理</div>
		</div>
		<div class="flex">
			<div>
				<el-form class="search" label-position='left' ref="seachForm" :model="seachForm" size="large">
                    <el-form-item style="margin-left:20px;display:flex;" label="结算日期：">
                        <el-date-picker
                            v-model="dateBetween"
                            @change="selecttime"
                            type="daterange"
                            range-separator="-"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
					</el-form-item>
                    <el-form-item style="margin-left:20px;">
						<el-input  :maxlength="50" style="width:300px;" v-model="seachForm.searchInfo" placeholder="请输入支付交易号/订单编号/供应商名称" clearable></el-input>
					</el-form-item>
					<el-form-item style="margin-left:20px;">
						<el-button  type="primary" @click="seachSumbit(seachForm)">搜索</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<div class="table-content">
			<el-table :cell-style="{ textAlign: 'center' }" :header-cell-style="{textAlign: 'center',backgroundColor:'#F5F7FA',color:'#333'}" :border="true" :data="data" style="width: 100%">
				<el-table-column prop="resultTime" label="结算日期" ></el-table-column>
				<el-table-column prop="orderSerialNo" label="支付交易号" ></el-table-column>
				<el-table-column prop="orderNo" label="订单编号" ></el-table-column>
				<el-table-column prop="dinddjf" label="订单已结算积分" ></el-table-column>
				<el-table-column prop="platformYijsjf" label="平台已结算积分" ></el-table-column>
				<el-table-column prop="supplierName" label="供应商名称" ></el-table-column>
				<el-table-column prop="supplierYijsjf" label="供应商已结算积分" ></el-table-column>
				<el-table-column prop="oneLevelPoints" label="一级分销已结算积分" ></el-table-column>
				<el-table-column prop="twoLevelPoints" label="二级分销已结算积分" ></el-table-column>
				<el-table-column prop="orderResultStatus" label="结算状态">
                    <template slot-scope="scope">
                        <span >{{scope.row.orderResultStatus == 0 ? '未结算' : '已结算'}}</span>
                    </template>
                </el-table-column>
			</el-table>
		</div>

		<div class="">
			<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
				:page-count="pageCount" :page-size="pageSize" layout="total, prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		managementList
	} from "@/api/platform/index";
	export default {
		name: "management",
		data() {
			return {
                seachForm:{
					resultTime: '',
					searchInfo: '',
				},
                dateBetween:'',
				data: [],
				page: 1,
				pageSize: 10,
				total: 0, //总数据
				currentPage: 0, //当前页页数
				pageCount: 0, //总页数
			};
		},
		mounted() {
			this.list();
		},
		methods: {
			//获取列表
			list() {
				managementList({
                    resultTime: this.seachForm.resultTime,
                    searchInfo:this.seachForm.searchInfo,
					page: this.page,
					size: this.pageSize,
				}).then(res => {
					this.data = res.data.records;
					this.total = res.data.total;
					this.currentPage = res.data.current;
					this.pageCount = res.data.pages;
					if (res.data.current > res.data.pages && res.data.pages != 0) {
						this.page = res.data.pages;
						this.list();
					}
				})
			},

            // 筛选开始结束日期
            selecttime(){
                if(this.dateBetween === null){
                    this.seachForm.resultTime = '';
                    return
                }
                let startime = this.dateBetween[0];
                let y = startime.getFullYear();
                let m = startime.getMonth() + 1;
                let r = startime.getDate();
                let h = startime.getHours();
                let mm = startime.getMinutes();
                let s = startime.getSeconds();
                m < 10 ? m = '0' + m : ''
                r < 10 ? r = '0' + r : ''
                h < 10 ? h = '0' + h : ''
                mm < 10 ? mm = '0' + mm : ''
                s < 10 ? s = '0' + s : ''
                let datetime = y + '-' + m + '-' + r;
                let endtime = this.dateBetween[1];
                let y1 = endtime.getFullYear();
                let m1 = endtime.getMonth() + 1;
                let r1 = endtime.getDate();
                let h1 = endtime.getHours();
                let mm1 = endtime.getMinutes();
                let s1 = endtime.getSeconds();
                m1 < 10 ? m1 = '0' + m1 : ''
                r1 < 10 ? r1 = '0' + r1 : ''
                h1 < 10 ? h1 = '0' + h1 : ''
                mm1 < 10 ? mm1 = '0' + mm1 : ''
                s1 < 10 ? s1 = '0' + s1 : ''
                let datetime1 = y1 + '-' + m1 + '-' + r1;
                this.seachForm.resultTime = datetime + ' ~ ' + datetime1;
            },
            
            //搜索提交
			seachSumbit(formName){
				this.page = 1
				this.list();
			},

			//分页
			handleCurrentChange(val) {
				this.page = val;
				this.list();
			},

		}
	}
</script>

<style scoped>
	.table-content {margin-top: 20px;}
	.active {color: #049EC4;}
	.form_box{padding:0 50px;}
	.avatar{width:150px;}
	.photo{width:150px;height:150px;border:1px solid #eee;display:flex;align-items: center;justify-content:center;font-size:40px;color:#ccc;}
	.flex{display:flex;align-items:center;justify-content:space-between;}

	.meunTop{display:flex;align-items:center;margin-bottom:20px;}
	.meunLine{width:3px;height:20px;background-color:#FFA218;}
	.meunTitle{font-size:20px;margin-left:10px;}
    .el-input__inner{
        -webkit-appearance: none !important;
        -moz-appearance: textfield !important;
    }
    .search .el-form-item{
        margin-bottom: 0;
    }
</style>
